<template>
  <div id="threeDMolWrapper" class="row" style="width: 100%">
    <div id="threeDMolWrapperView" class="col col-md-6 col-sm-12">
      <div class="row border-bottom">
        <div class="col col-12 smaller">
          <div class="alert alert-warning" style="margin-bottom: 0px; padding: 0.75rem" v-if="positionIsOutside">
            Position {{ clickedResidue }} is not within structurally
            characterized protein.
          </div>
        </div>
      </div>
      <div class="row" id="structureView"></div>
    </div>
    <div id="threeDMolWrapperControls" class="col col-md-6 col-sm-12 pl-3" v-if="selectedPDB.id">
      <div class="row alert alert-info" v-if="showRandomSelectBox">
        <div>
          Click on any structure above to display 3d view
        </div>
      </div>
      <div class="row">
        <div class="col col-9">
          <h5>
            {{
            selectedPDB.isModel
              ? "Selected model based on"
              : "Selected structure"
          }}:
            <span class="selected-pdb">{{ selectedPDB.properties.pdbid }}</span>
          </h5>
        </div>

        <div class="col col-3">
          <div class="float-right">
            <div class="dropdown">
              <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <span class="align-middle material-icons">save_alt</span>
                Download
              </button>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <a _target="blank" class="dropdown-item" v-bind:href="selectedPDB.url" title="Download PDB file">
                  <span class="align-middle">PDB&nbsp;file</span>
                </a>
                <div class="dropdown-divider"></div>
                <a _target="blank" class="dropdown-item" v-on:click="exportPyMol()" title="Export PyMol script">
                  <span class="align-middle">PyMol&nbsp;script</span>
                </a>
                <a _target="blank" class="dropdown-item" v-on:click="exportChimera()" title="Export Chimera script">
                  <span class="align-middle">Chimera&nbsp;script</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <table v-if="selectedPDB.isModel && selectedPDB.properties.Method === 'ALPHAFOLD'
            ">
        <tr>
          <th>Model</th>
          <td>
            {{ selectedPDB.label }}
          </td>
        </tr>
        <tr>
          <th>Model title</th>
          <td>{{ selectedPDB.properties.Title }}</td>
        </tr>
        <tr>
          <th>Model range</th>
          <td>{{ selectedPDB.start }}:{{ selectedPDB.end }}</td>
        </tr>
        <tr>
          <th>Model determination method</th>
          <td>
            <span v-bind:style="{ color: selectedPDB.color }">{{
            selectedPDB.properties.Method
          }}</span>
          </td>
        </tr>
      </table>
      <table v-else>
        <tr>
          <th>
            {{ selectedPDB.isModel ? "Template PDB chain" : "PDB Chain" }}
          </th>
          <td>
            <a target="_blank" v-bind:href="'//www.rcsb.org/structure/' + selectedPDB.properties.pdbid
            ">{{
            selectedPDB.label ||
            selectedPDB.properties.pdbid + selectedPDB.chain
          }}</a>
          </td>
        </tr>
        <tr v-if="selectedPDB.properties.Title">
          <th>{{ selectedPDB.isModel ? "Template PDB title" : "Title" }}</th>
          <td>{{ selectedPDB.properties.Title }}</td>
        </tr>
        <tr>
          <th>{{ selectedPDB.isModel ? "Template range" : "Position" }}</th>
          <td>{{ selectedPDB.start }}:{{ selectedPDB.end }}</td>
        </tr>
        <tr v-if="selectedPDB.properties['Sequence identity']">
          <th>Sequence Identity</th>
          <td>{{ selectedPDB.properties["Sequence identity"] }}</td>
        </tr>
        <tr v-if="selectedPDB.properties.Method">
          <th>
            {{
            selectedPDB.isModel ? "Template determination method" : "Method"
          }}
          </th>
          <td>
            <span v-bind:style="{ color: selectedPDB.color }">{{
            selectedPDB.properties.Method
          }}</span>
          </td>
        </tr>
        <tr v-if="selectedPDB.isCSGID">
          <th>Comment</th>
          <td>
            <span>{{ selectedPDB.properties.comment }}</span>
          </td>
        </tr>
      </table>

      <div class="row border-top mt-2 pt-2">
        <div class="col col-4">
          <b style="margin-right: 0rem">Display&nbsp;as:</b>
        </div>
        <div class="col">
          <div class="row">
            <button type="button" v-on:click="
            set3DStyle({
              style: 'cartoon',
              viewstyle: 'rectangle',
              styleName: 'ribbon',
            })
            " class="btn btn-sm" v-bind:class="{
            'btn-outline-success': viewStyle.styleName != 'ribbon',
            'btn-success': viewStyle.styleName === 'ribbon',
          }">
              Ribbon
            </button>
            <button type="button" class="btn btn-sm" v-bind:class="{
            'btn-outline-info': viewStyle.styleName != 'stick',
            'btn-info': viewStyle.styleName === 'stick',
          }" v-on:click="set3DStyle({ style: 'stick', styleName: 'stick' })">
              Stick
            </button>
            <button type="button" class="btn btn-sm" v-bind:class="{
            'btn-outline-primary': viewStyle.styleName != 'trace',
            'btn-primary': viewStyle.styleName === 'trace',
          }" v-on:click="
            set3DStyle({
              style: 'cartoon',
              viewstyle: 'trace',
              styleName: 'trace',
            })
            ">
              CA trace
            </button>
            <button type="button" v-bind:class="{
            'btn-outline-secondary': !showSurface,
            'btn-secondary': showSurface,
          }" class="btn btn-sm ml-2" v-on:click="toggleSurface(!showSurface)">
              Chain surface
            </button>
          </div>
          <div class="row pt-2">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="hideOtherChainsChk" v-model="hideOtherChains"
                v-on:change="rerenderColors()" />
              <label class="form-check-label" for="hideOtherChainsChk">
                Hide secondary chains
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row border-top mt-2 pt-2" v-if="selectedPDB.properties && selectedPDB.properties.alignment_start">
        <div class="col col-4">
          <label for="currentColoring"><b>Color structure by:</b></label>
        </div>
        <div class="col pl-0">
          <select class="form-control" id="currentColoring" name="currentColoring" size="1" v-model="currentColoring"
            @change="set3DStyle({ color: currentColoring })">
            <option value="avgmutation">
              Rate of protein mutations ({{ currentWindow }}nt. average)
            </option>
            <option value="mutation">Total count of missense mutations</option>
            <option value="spectrum">Residue number</option>
            <option v-for="(v, k) in extraColoring" :key="k" :value="k">
              {{ v.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-3 pl-3"
        v-if="viewStyle.color != 'avgmutation' && viewStyle.color != 'mutation' && viewStyle.color != 'spectrum'">

        <div class="row pt-1"
          v-if="extraColoring && extraColoring[currentColoring] && extraColoring[currentColoring].legend">
          <div class="col col-12">
            <b>Legend:</b>
            <svg id="customLegendFor3d" width="100%" height="100%">
              <g v-for="(v, k, i) in extraColoring[currentColoring].legend" :key="k" :value="k">
                <rect :transform="'translate(0, ' + (20 * i) + ')'" width="15" height="15" :style="'fill: ' + v.color"
                  stroke="#BBB"></rect>
                <text x="20" :y="20 * (i + 1) - 9" style="fill: #000; font-size: 9pt; text-anchor: start">
                  {{ v.label }} {{ extraColoring[currentColoring].data[k.toLowerCase()] }}
                </text>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="row mt-3 pl-3" v-if="viewStyle.color === 'avgmutation' || viewStyle.color === 'mutation'">
        <div class="row">
          <div class="col col-6 form-group">
            <label for="currentScheme"><b>Select coloring scheme:</b></label>
            <select class="form-control" id="currentScheme" name="currentScheme" size="1" v-model="currentScheme"
              @change="set3DStyle({})">
              <option v-for="(value, index) in coloringSchemes" :key="index" :value="index">
                {{ value.label }}
              </option>
            </select>
          </div>
          <div class="col col-6">
            <div class="row form-check">
              <input class="form-check-input" type="checkbox" value="" id="checkReverse" v-model="isReversed"
                @change="set3DStyle({})" />
              <label class="form-check-label" for="checkReverse">
                Reverse
              </label>
            </div>
            <div class="row pt-1">
              <svg id="legendFor3d" width="100%" height="40px">
                <defs>
                  <linearGradient id="legendGradient">
                    <stop offset="0%" :stop-color="activeColors[0]"></stop>
                    <stop offset="25%" :stop-color="activeColors[1]"></stop>
                    <stop offset="50%" :stop-color="activeColors[2]"></stop>
                    <stop offset="75%" :stop-color="activeColors[3]"></stop>
                    <stop offset="100%" :stop-color="activeColors[4]"></stop>
                  </linearGradient>
                </defs>
                <text x="0" y="13" style="fill: #000; font-size: 8pt; text-anchor: start">
                  {{ toPrecision(residueColoringStats.min, 2, "floor") || 0 }}
                </text>
                <text x="50" y="13" style="fill: #000; font-size: 8pt; text-anchor: middle">
                  {{ toPrecision(domain[1], 1, "round") || 0 }}
                </text>
                <text x="100" y="13" style="fill: #000; font-size: 8pt; text-anchor: middle">
                  {{ toPrecision(residueColoringStats.mean, 2, "round") || 0 }}
                </text>
                <text x="150" y="13" style="fill: #000; font-size: 8pt; text-anchor: middle">
                  {{ toPrecision(domain[3], 1, "round") || 0 }}
                </text>
                <text x="200" y="13" style="fill: #000; font-size: 8pt; text-anchor: end">
                  {{ toPrecision(residueColoringStats.max, 2, "ceil") || 0 }}
                </text>
                <rect transform="translate(0, 20)" width="200" height="20" style="fill: url('#legendGradient')"></rect>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div class="row border-top pt-2" v-if="enablePositionSelection">
        <div class="col" v-if="selectedPDB.properties && selectedPDB.properties.alignment_start
            ">
          <b>Filter positions to display:</b>
          <div class="form-row m-2">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="filterCoord"
                v-model="positionFilterType" value="c" />
              <label class="form-check-label" for="filterCoord">By position</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="filterDist" value="d"
                v-model="positionFilterType" @change="applyPositionsFilter()" :disabled="availableLigands.length === 0"
                :alt="availableLigands.length === 0
            ? 'No ligands available'
            : 'Select to filter mutations based on distance from ligand'
            " />
              <label class="form-check-label" for="filterDist">By distance from ligand</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="filterChain" value="ch"
                v-model="positionFilterType" @change="applyPositionsFilter()" :disabled="availableChains.length === 0"
                :alt="availableChains.length === 0
            ? 'No other chains available'
            : 'Select to filter mutations based on distance from chain'
            " />
              <label class="form-check-label" for="filterChain">By distance from chain</label>
            </div>
          </div>
          <div class="form-row" v-if="positionFilterType === 'c'">
            <div class="form-group col-md-6 col-sm-12">
              <label for="inputPositions">Positions to display</label>
              <input type="text" id="inputPositions" v-model.trim="selectedPositionsStr"
                class="form-control form-control-sm" placeholder="Positions"
                aria-describedby="inputPositionsHelpBlock" />
              <small id="inputPositionsHelpBlock" class="form-text text-muted">
                Enter list of positions in protein coordinates, separated by a
                comma or whitespace, and click UPDATE.
              </small>

            </div>
            <div class="form-group col-md-4 col-sm-12 pt-4">
              <fieldset class="form-group row">
                <div class="col-sm-12">
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="radiosCoord" id="radiosGen"
                      v-model="coordinatesType" value="g" checked />
                    <label class="form-check-label" for="radiosGen">
                      Genomic coordinates
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" name="radiosCoord" id="radiosStruct"
                      v-model="coordinatesType" value="s" />
                    <label class="form-check-label" for="radiosStruct">
                      Structure coordinates
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="form-group col-md-2 col-sm-12 pt-4">
              <button type="button" @click="applyPositionsFilter()" class="btn btn-primary">
                Update
              </button>
            </div>
            <div class=" form-row p-1 mb-2 bg-light text-dark" v-if="presetPositions && presetPositions.length">
              <span class="small">List of position affecting binding from the literarure (click on position to add it to
                the list or
                <span class="badge badge-pill badge-secondary text-white" @click="addPosToList()">add
                  all</span>):</span>
              <span v-for="p in presetPositions" v-bind:key="p.pos" @click="addPosToList(p.pos)"
                class="badge badge-pill badge-secondary text-white ">
                {{ p.pos }}<sup v-for="(e, i) in p.evidences" v-bind:key="i"><a title="Open reference"
                    class="text-white" v-bind:href="e" target="_blank">[{{
            references.get(e) || 'ref'
          }}]</a></sup>
              </span>
            </div>
          </div>
          <div class="form-row" v-if="positionFilterType === 'd'">
            <div class="form-group col-md-8">
              <label for="selectedLigand">Ligand to display:</label>
              <select class="form-control form-control-sm" id="selectedLigand" v-model="selectedLigand"
                @change="applyPositionsFilter()" :disabled="availableLigands.length === 0">
                <option v-for="(p, j) in availableLigands" v-bind:value="p" v-bind:key="j">
                  {{ p.id }} [chain {{ p.chain }}]
                </option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="distanceToLigand">Distance (&#8491;):</label>
              <select class="form-control form-control-sm" id="distanceToLigand" v-model="distanceToLigand"
                @change="applyPositionsFilter()">
                <option value="5" selected>5</option>
                <option value="4.5">4.5</option>
                <option value="4">4</option>
                <option value="3.5">3.5</option>
                <option value="3">3</option>
              </select>
            </div>
            <small id="inputDistanceHelpBlock" class="form-text text-muted">
              Select ligand to display and max distance, missense mutation
              charts will update automatically
            </small>
          </div>
          <div class="form-row" v-if="positionFilterType === 'ch'">
            <div class="form-group col-md-8">
              <label for="selectedAntibody">Select chain:</label>
              <select class="form-control form-control-sm" id="selectedAntibody" v-model="selectedAntibody"
                @change="applyPositionsFilter()" :disabled="availableChains.length === 0">
                <option v-for="p in availableChains" v-bind:value="p" v-bind:key="p.id">
                  {{ p.id }} [chain {{ p.chain }}]
                </option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="distanceToAntibody">Distance (A):</label>
              <select class="form-control form-control-sm" id="distanceToAntibody" v-model="distanceToAntibody"
                @change="applyPositionsFilter()">
                <option value="5" selected>5</option>
                <option value="4.5">4.5</option>
                <option value="4">4</option>
                <option value="3.5">3.5</option>
                <option value="3">3</option>
              </select>
            </div>
            <small id="inputDistanceHelpBlock" class="form-text text-muted">
              Select protein chain to display and max distance, missense mutation
              charts will update automatically.
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Shared from "@/shared/Shared.js";
import Mutations from "@/shared/Mutations.js";
export default {
  name: "threeDMolWrapper",

  mixins: [Shared, Mutations],
  props: {
    loading: Boolean,
    showRandomSelectBox: Boolean,
    enablePositionSelection: Boolean,
    mappedPosition: Number,
    clickedResidue: Number,
    selectedPDB: {},
    mutations: Array, // filtered mutations,
    mutationCounts: Array, // averaged mutation count for the whole genome
    presetPositions: Array,
    references: Map,
    extraColoring: Object, // extra coloring data
  },
  data: function () {
    return {
      START: Date.now(),
      positionIsOutside: Boolean,
      currentColoring: "avgmutation",
      currentScheme: "warm",
      isReversed: false,
      hideOtherChains: false,
      activeColors: [],
      domain: [],
      coloringSchemes: {
        warm: {
          label: "Warm (Light Green -> Light Purple)",
          colors: ["#aff05b", "#efa72f", "#ff5e63", "#d23ea7", "#6e40aa"],
          ligand: "#253494",
        },
        yellowRed: {
          label: "Green->Yellow->Red",
          colors: ["#c2e699", "#fbff19", "#FF9800", "#F6412D", "#ff0000"],
          ligand: "#253494",
        },
        yellowOrRed: {
          label: "Yellow->Red",
          colors: ["#ffffb2", "#fecc5c", "#fd8d3c", "#f03b20", "#bd0026"],
          ligand: "#253494",
        },
        viridis: {
          label: "Purple->Green->Yellow", // viridis
          colors: ["#440154", "#3b528b", "#21918c", "#5ec962", "#ffffb2"],
          ligand: "#ff0000",
        },
        ylBlue: {
          label: "Yellow-Blue",
          colors: ["#ffffcc", "#a1dab4", "#41b6c4", "#2c7fb8", "#253494"],

          ligand: "#ff0000",
        },
        yllGrn: {
          label: "Yellow-Green",
          colors: ["#ffffcc", "#c2e699", "#78c679", "#31a354", "#006837"],

          ligand: "#ff0000",
        },
        purRed: {
          label: "Purple->Red",
          colors: ["#f1eef6", "#d7b5d8", "#df65b0", "#dd1c77", "#980043"],
          ligand: "#253494",
        },
        plasma: {
          label: "Plasma",
          colors: ["#f0f921", "#f89540", "#cc4778", "#7e03a8", "#0d0887"],
          ligand: "#31a354",
        },
        spectral: {
          label: "Spectral(Red -> Blue)",
          colors: ["#d7191c", "#fdae61", "#ffffbf", "#abdda4", "#2b83ba"],
          ligand: "#0d0887",
        },
      },
      // keep separate objects for coloring, each having {sum;count;value} items for residue
      // keys should match select box values, except for the rainbow one
      residueColoringData: {
        mutation: {},
        avgmutation: {},
      },
      currentWindow: 100,
      avgWindows: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10],
      residueColoringStats: {
        min: 0,
        max: 0,
        mean: 0,
        // formzted to required presision for display
        nicemin: 0,
        nicemax: 0,
        nicemean: 0,
      },
      legendHeight: 20,
      viewStyle: {
        chain: {},
        style: "cartoon",
        viewstyle: "rectangle", // only for cartoom
        color: "mutation",
        styleName: "ribbon", // name for css classes toggle
      },
      showSurface: false,
      colorScale: null,
      viewer: null, // 3dmol viewerx
      currentLigand: null, // 3dmol AtomSpec for currewntly selected ligand
      // positions filter props
      positionFilterType: "a", // a - all, d - distance, c - coordinates
      coordinatesType: "s",
      selectedPositionsStr: "",
      selectedPositions: [], // keep resN of selected positions
      /** ligands are parsed out of pdb file */
      availableLigands: [],
      distanceToLigand: 5,
      selectedLigand: {},
      /** all chains in model, parsed out of pdb file */
      availableChains: [],
      distanceToAntibody: 5,
      selectedAntibody: {},
    };
  },
  mounted() {
    this.init3DViewer();
  },
  ready() {
    this.$on("selection_clear", () => {
      this.viewer.clear();
      this.log("Cleared");
    });
  },
  watch: {
    selectedPDB: function (newPDB) {
      if (!newPDB) {
        return;
      }
      this.selectedPositionsStr = "";
      this.selectedPositions = [];
      this.residueColoringData = { mutation: {}, avgmutation: {} };
      this.currentColoring = "avgmutation";
      // this.log(`new Pdb selected ${newPDB}`);

      this.updateMutationsData(this.mutations); // not sure if this is needed? PDB and mutation are updated at the same time
      this.updateAvgMutationData(this.mutationCounts);
      this.displayPDB(newPDB);
    },
    selectedLigand: function (newLigand) {
      this.toggleLigand(newLigand);
      if (newLigand) {
        this.positionFilterType = "d";
        this.applyPositionsFilter();
      } else {
        this.positionFilterType = "a";
      }
    },
    loading: function () {
      this.applyPositionsFilter();
    },
    mappedPosition: function (v) {
      if (v && v > 0) {
        this.positionIsOutside = false;
        if (this.viewer) {
          const atom = this.viewer.selectedAtoms({
            chain: this.selectedPDB.chain,
            atom: "CA",
            resi: v,
          })[0];
          this.set3DStyle({});
          this.labelAtom(atom);
          this.markMapped(v);
        }
        this.log(`Selected position: ${v}`);
      } else {
        this.positionIsOutside = true;
      }
    },
    mutations: function (v) {
      // this.log(`mutations changed ${v}`);

      if (Array.isArray(v)) {
        //&& v.length > 0) {
        this.updateMutationsData(v);
        this.applyPositionsFilter();
      }
    },
    mutationCounts: function (v) {
      // this.log(`avg mutations changed ${v}`);
      this.updateAvgMutationData(v);
    },
  },
  methods: {
    log: function (msg) {
      const t = Date.now() - this.START;
      // eslint-disable-next-line no-console
      console.log(`3D@ ${t}ms: ${msg}`);
    },
    clear: function () {
      this.viewer && this.viewer.clear();
      this.showSurface = false;
      this.positionFilterType = "a";
      this.selectedLigand = null;
      this.log("Cleared");
    },
    dna2protS(x) {
      const start = this.selectedPDB.start;
      const end = this.selectedPDB.end;
      const first = this.selectedPDB.properties.alignment_start;

      // return this.$parent.dna2prot(x, start, end, first);
      return this.dna2prot(x, start, end, first);
    },
    prot2dnaS(p) {
      const start = this.selectedPDB.start;
      const end = this.selectedPDB.end;
      const first = this.selectedPDB.properties.alignment_start;
      return this.prot2dna(p, start, end, first);
    },
    init3DViewer() {
      this.positionIsOutside = false;
      const waitInterval = setInterval(waaait, 1000);
      const self = this;

      function waaait() {
        if (!window.$3Dmol) self.log("Waiting for 3dmol...");
        else {
          doCreate();
          clearInterval(waitInterval);
        }
      }

      function getWidth() {
        let width =
          Math.min(
            document.documentElement.clientWidth,
            window.innerWidth || 0
          ) - 30;
        if (width > 730) width = width / 2;
        return width;
      }

      function getHeight() {
        const h = window.jQuery("#threeDMolWrapperControls").height();
        return h;
      }

      function doCreate() {
        const width = getWidth();
        const height = getHeight();

        window.jQuery("#structureView").width(width);
        window.jQuery("#structureView").height(height);

        self.viewer = window.$3Dmol.createViewer("structureView", {
          backgroundColor: 0xfefefefe,
        });

        self.viewer.setWidth(width);
        self.viewer.setHeight(height);

        window.jQuery("#structureView").width(getWidth());
        window.jQuery("#structureView").height(getHeight());
        self.viewer.resize();

        window.onresize = function () {
          window.jQuery("#structureView").width(getWidth());
          window.jQuery("#structureView").height(getHeight());
          self.viewer.resize();
        };

        self.log("3Dmol loaded");

        self.displayPDB();
      }
    },
    canApplyColors() {
      // Check if viewer is ready and mutations data is there
      if (!this.viewer) {
        this.log("3Dmol is not yet ready");
        return false;
      }
      if (this.loading) {
        this.log("Mutations are not yet ready");
        return false;
      }
      if (!this.selectedPDB || !this.selectedPDB.properties) {
        this.log("Structure not selected");
        return false;
      }

      if (this.selectedPDB.properties.alignment_start < 0) {
        alert(
          "Currently implemented only for structures with valid alignment start"
        );
        return false;
      }

      return true;
    },
    updateColorScale() {
      // recalsulate colorScale based on the input data stats

      const min = this.residueColoringStats.min;
      const max = this.residueColoringStats.max;
      const mean = this.residueColoringStats.mean;
      const needLogScale = max > 200;

      const domain = [
        min,
        min + (mean - min) / 2,
        mean,
        mean + (max - mean) / 2,
        max,
      ];

      this.domain = domain; // save for labelling

      this.activeColors =
        this.coloringSchemes[this.currentScheme].colors.slice();
      this.isReversed && this.activeColors.reverse();

      if (needLogScale) {
        this.colorScale = window.d3
          .scaleSequentialPow()
          .domain(domain)
          .range(this.activeColors);
      } else {
        // Linear Scale
        this.colorScale = window.d3
          .scaleSequential()
          .domain(domain)
          .range(this.activeColors);
      }
      this.$emit("update:v3d-colorscale", this.colorScale);
    },
    updateMutationsData(data) {
      /// Called when selected PDB or mutations have been changed
      this.residueColoringData.mutation = {};
      this.mAtoms = {};
      if (!data || data.length === 0) return;
      // UNFINISHED! Uses old code for coloring now

      const self = this;
      this.mutations.forEach((m) => {
        if (
          m.variant >= this.selectedPDB.start &&
          m.variant <= this.selectedPDB.end
        ) {
          // mutation could be mapped to protein
          const mappedPosition = self.dna2protS(m.variant);

          if (mappedPosition && mappedPosition > 0) {
            let d = self.residueColoringData.mutation[mappedPosition] || {
              sum: 0, //sum of mutations
              count: 0, //count of summed elements to calculate average
            };

            d.sum += Number.parseFloat(m.mutationNumber);
            d.count++;
            d.value = d.sum;

            this.residueColoringData.mutation[mappedPosition] = d;
            /////////-------------------

            let atom = self.mAtoms[mappedPosition] || {};

            atom.mutations = atom.mutations || [];
            atom.mutations.push(m);

            atom.count = atom.count
              ? atom.count + m.mutationNumber
              : m.mutationNumber;

            self.mAtoms[mappedPosition] = atom;
          }
        }
      });
    },
    updateAvgMutationData(rawdata) {
      /// Called when selected PDB or avgMutationCounts have been changed

      this.residueColoringData.avgmutation = {};
      if (!rawdata || rawdata.length === 0) return;

      let data = this.averageOverWindow(rawdata, this.currentWindow);

      const start = this.selectedPDB.start;
      const end = this.selectedPDB.end;

      for (let i = start; i < end; i++) {
        let mappedPosition = this.dna2protS(i);
        if (mappedPosition && mappedPosition > 0) {
          // this should always be the case, otherwise we have a problem

          let d = this.residueColoringData.avgmutation[mappedPosition] || {
            sum: 0, //sum of mutations
            count: 0, //count of summed elements to calculate average
            value: 0, // sum/count
          };

          d.sum += Number.parseFloat(data[i]);
          d.count++;

          // this.log(`${i} ${mappedPosition} ${data[i]} ${d.sum} ${d.count}`);
          this.residueColoringData.avgmutation[mappedPosition] = d;
        } else {
          this.log(`ERROR: mapping issue for position ${i}`);
        }
      }
      Object.values(this.residueColoringData.avgmutation).forEach((m) => {
        m.value = m.count === 0 ? 0 : m.sum / m.count;
      });
    },
    setAvgWindow() {
      this.log("todo");
      this.updateAvgMutationData(this.mutationCounts);
      this.set3DStyle({});
    },
    getColorForValue(value) {
      if (value < this.residueColoringStats.min) {
        //how come???
        this.log(
          `Value below min (${this.residueColoringStats.min}): ${value}`
        );
        return this.activeColors[0];
      } else if (value > this.residueColoringStats.max) {
        //how come again???
        this.log(
          `Value above max (${this.residueColoringStats.max}): ${value}`
        );
        return this.activeColors[this.activeColors.length - 1];
      }

      if (value === 0) return "#fffff";
      if (value === this.residueColoringStats.max) {
        return this.activeColors[this.activeColors.length - 1]; // is this one needed?
      }
      let color = window.d3.color(this.colorScale(value)).formatHex();
      // this.log(`value ${value} color ${color}`);

      return color;
    },
    getColorForResidue(resi) {
      if (
        this.positionFilterType != "a" &&
        !this.selectedPositions.includes(resi)
      )
        return "white";

      let v = this.residueColoringData[this.viewStyle.color]
        ? this.residueColoringData[this.viewStyle.color][resi]
        : null;
      return v ? this.getColorForValue(v.value) : "white";
    },
    toPrecision(value, precision, method) {
      precision = precision || 2;

      const m = Math.pow(10, precision) || 1;
      if (method === "round") {
        return Math.round(m * value) / m;
      } else if (method === "ceil") {
        return Math.ceil(m * value) / m;
      } else {
        return Math.floor(value * m) / m;
      }
    },
    getArrayStats(data, accessor) {
      /* Using for loop for performace, see https://medium.com/coding-at-dawn/the-fastest-way-to-find-minimum-and-maximum-values-in-an-array-in-javascript-2511115f8621*/
      if (!data.length) return null;
      let min;
      let max;
      let total = 0;

      if (accessor === undefined) {
        for (const v of data) {
          if (v === undefined || v === null) continue;
          total += v;
          if (max < v || (max === undefined && v >= v)) {
            max = v;
          }
          if (min > v || (min === undefined && v >= v)) {
            min = v;
          }
        }
      } else {
        let index = -1;
        for (let value of data) {
          let v = accessor(value, ++index, data);
          if (v === undefined || v === null) continue;
          total += v;
          if (max < v || (max === undefined && v >= v)) {
            max = v;
          }
          if (min > v || (min === undefined && v >= v)) {
            min = v;
          }
        }
      }

      let mean = total / data.length;

      return {
        min: min,
        max: max,
        mean: mean,
      };
    },
    updateDataStats(data, accessor) {
      // updates stats on values of the object
      this.residueColoringStats = this.getArrayStats(
        Object.values(data),
        accessor
      ) || { min: 0, max: 0, mean: 0 };
    },
    recolorStructure() {
      if (!this.canApplyColors()) return;
      // take an array of values as input, color structure using these value and current coloring scheme

      this.residueColoringStats = {
        min: 0,
        max: 0,
        mean: 0,
      };

      // make sure it's an object, not observable https://github.com/vuejs/Discussion/issues/292
      let d = this.residueColoringData[this.viewStyle.color];

      let data = d ? JSON.parse(JSON.stringify(d)) : null;

      if (!data || Object.keys(data).length === 0) {
        //sync issue?
        if (this.viewStyle.color === "mutation") {
          this.updateMutationsData(this.mutations);
        } else if (this.viewStyle.color === "avgmutation") {
          this.updateAvgMutationData(this.mutationCounts);
        }
        //and try again
        d = this.residueColoringData[this.viewStyle.color];
        data = d ? JSON.parse(JSON.stringify(d)) : null;
      }

      // this.log(data);
      if (!data) {

        // eslint-disable-next-line no-console
        console.error("No data available");
        return;
      }

      // self.log(data);
      this.updateDataStats(data, (d) => d.value);

      this.updateColorScale();

      this.rerenderColors();
      this.toggleSurface(this.showSurface);
      this.markMapped(this.mappedPosition);
      this.toggleLigand(this.selectedLigand);
    },
    rerenderColors: function () {
      const self = this;

      let style = {},
        styleChain = {},
        chain = { chain: this.selectedPDB.chain };

      styleChain[this.viewStyle.style] = { colorfunc: self.colorMutated };

      if (this.viewStyle.style === "stick" || this.viewStyle.style === "line") {
        style[this.viewStyle.style] = { color: "#D5D8DC", opacity: 1 };
      } else {
        style[this.viewStyle.style] = { color: "white", opacity: 0.5 };
      }

      if (this.viewStyle.style === "cartoon") {
        style[this.viewStyle.style]["style"] = this.viewStyle.viewstyle;
        styleChain[this.viewStyle.style]["style"] = this.viewStyle.viewstyle;

        if (this.viewStyle.viewstyle === "trace") {
          // need to enable atoms for mouse events
          chain["atom"] = "CA";
          styleChain["clicksphere"] = { radius: 1 };
        }
      }

      if (self.hideOtherChains) {
        style[this.viewStyle.style]["hidden"] = true;
      }

      const v = this.viewer;
      v.setStyle({}, style);

      if (this.positionFilterType === "ch" && this.selectedAntibody) {
        // color chain which is used to filter positions
        const c = this.coloringSchemes[this.currentScheme].ligand
          ? this.coloringSchemes[this.currentScheme].ligand
          : "#222222";
        let abChainStyle = style;
        abChainStyle[this.viewStyle.style].color = c;
        v.setStyle({ chain: this.selectedAntibody.chain }, abChainStyle);
      }

      v.setStyle(chain, styleChain);

      let label;
      v.setHoverable(
        { chain: this.selectedPDB.chain },
        true,
        function (atom, viewer) {
          const m = self.mAtoms[atom.resi]; //atom with mutations
          const l = self.labelForAtom(atom, m);

          if (m && !atom.label) {
            label = viewer.addLabel(l, {
              fontSize: 12,
              position: atom,
              backgroundColor: self.getColorForResidue(atom.resi),
              backgroundOpacity: 0.7,
              fontColor: "black",
              borderColor: "#111111",
              borderThickness: 1.0,
            });
            atom.label = label;
            setTimeout(function () {
              v.removeLabel(label);
              delete atom.label;
              viewer.render();
            }, 750);
          }
        },
        function (atom) {
          v.removeLabel(label);
          delete atom.label;
          v.render();
        }
      );

      v.setClickable({}, true, function (atom) {
        self.labelAtom(atom);
        const x = self.prot2dnaS(atom.resi);
        x > 0 && self.zoomToCodon(x);
      });

      this.toggleLigand(this.selectedLigand);
      v.render();
    },
    labelForAtom: function (atom, mutationInfo) {
      // atom is the 3dMol atom
      // mutationInfo contains list of mutations for corresponding codon
      if (mutationInfo) {
        if (!mutationInfo.label) {
          let mutLabel = "";
          mutationInfo.mutations.forEach(
            (m) => (mutLabel = mutLabel + m.alt + " ")
          );
          mutationInfo.label =
            atom.resn +
            atom.resi +
            "[count " +
            mutationInfo.count +
            ": " +
            mutLabel +
            "]";
        }
        return mutationInfo.label;
      }
      return atom.resn + atom.resi;
    },
    colorMutated: function (atom) {
      return this.getColorForResidue(atom.resi);
    },
    resetColor() {
      this.set3DStyle({ color: "spectrum" });
    },
    toggleSurface(show) {
      this.showSurface = show;

      const self = this;
      this.viewer.removeAllSurfaces();
      if (!show) {
        return;
      }
      // gradient for rainbow
      let range = window.$3Dmol.getPropertyRange(
        this.viewer.selectedAtoms({
          chain: this.selectedPDB.chain,
          atom: "CA",
        }),
        "resi"
      );

      const gradient = new window.$3Dmol.Gradient.Sinebow(range[1], range[0]);


      let defaultColorFunc = function (atom) {
        const c = gradient.valueToHex(atom.resi);

        if (atom.hetflag) return "darkgray";
        return c;
      };

      let colors;
      if (
        this.viewStyle.color === "mutation" ||
        this.viewStyle.color === "avgmutation"
      ) {
        colors = { colorfunc: this.colorMutated };
      } else if (this.viewStyle.color !== "spectrum") {
        // one of the custom coloring schemes
        const coloring = this.extraColoring[this.viewStyle.color];
        if (coloring && coloring.func) {
          colors = {
            colorfunc: function (atom) {
              return coloring.func(atom,
                JSON.parse(JSON.stringify(coloring.data || {})),
                JSON.parse(JSON.stringify(coloring.legend || {})));
            }
          };
        } else {
          colors = { colorfunc: defaultColorFunc };
        }
      } else
        colors = { colorfunc: defaultColorFunc };

      this.viewer.addSurface(window.$3Dmol.SurfaceType.SAS, colors, {
        predicate: function (atom) {
          return (
            atom.chain === self.selectedPDB.chain &&
            atom.resn != "HOH" &&
            !atom.hetflag
          );
        },
      });
    },
    markMapped(resn) {
      if (!this.enablePositionSelection) return;
      if (resn < 0) return;
      const atomsSel = {
        chain: this.selectedPDB.chain,
        resi: resn,
      };
      this.viewer.addStyle(atomsSel, {
        stick: {
          color: this.getColorForResidue(resn),
          radius: 0.75,
          opacity: 1,
        },
      });
      this.viewer.render();
    },
    toggleLigand(ligand) {
      if (!this.enablePositionSelection || !this.viewer) {
        return;
      }
      const v = this.viewer;
      // clear currentLigand, if any
      if (this.currentLigand) {
        v.setStyle(this.currentLigand, {});
      }

      //show new ligand
      if (ligand && ligand.resn) {
        let atomSpec = { resn: ligand.resn, chain: ligand.chain };
        this.currentLigand = atomSpec;
        // const c = this.coloringSchemes[this.currentScheme].ligand
        //   ? this.coloringSchemes[this.currentScheme].ligand
        //   : "#222222";
        // let style = { stick: { color: c, opacity: 1 } };

        let style = { stick: { colorscheme: "yellowCarbon", opacity: 1 } };

        v.addStyle(atomSpec, style);
        v.center(atomSpec);
        v.zoomTo(atomSpec);
      } else {
        v.zoomTo();
      }
      v.render();
    },
    set3DStyle(newStyle) {
      const v = this.viewer;
      this.viewStyle.styleName = newStyle.styleName || this.viewStyle.styleName;
      this.viewStyle.chain = newStyle.chain || this.viewStyle.chain;
      this.viewStyle.color = newStyle.color || this.viewStyle.color;
      this.viewStyle.style = newStyle.style || this.viewStyle.style;
      this.viewStyle.viewstyle = newStyle.viewstyle || this.viewStyle.viewstyle;

      if (
        this.viewStyle.color === "mutation" ||
        this.viewStyle.color === "avgmutation"
      ) {
        this.recolorStructure();
      } else if (this.viewStyle.color !== "spectrum") {
        // one of the custom coloring schemes
        const coloring = this.extraColoring[this.viewStyle.color];
        if (coloring && coloring.func) {
          let s = {};
          s[this.viewStyle.style] = {
            colorfunc: function (atom) {
              return coloring.func(atom,
                JSON.parse(JSON.stringify(coloring.data || {})),
                JSON.parse(JSON.stringify(coloring.legend || {})));
            }
          };

          // s[this.viewStyle.style] = { color: this.viewStyle.color };
          if (this.viewStyle.style === "cartoon") {
            s[this.viewStyle.style]["style"] = this.viewStyle.viewstyle;
          }
          v.setStyle(this.viewStyle.chain, s);
          v.render();
        }
      } else {// default, spectrum
        let s = {};
        s[this.viewStyle.style] = { color: this.viewStyle.color };

        if (this.viewStyle.style === "cartoon") {
          s[this.viewStyle.style]["style"] = this.viewStyle.viewstyle;
        }
        v.setStyle(this.viewStyle.chain, s);
        v.render();
      }
      this.toggleSurface(this.showSurface);
      this.markMapped(this.mappedPosition);
      this.toggleLigand(this.selectedLigand);
    },
    displayPDB() {
      const self = this;
      self.clear();

      const pdbid = self.selectedPDB.id;

      if (!pdbid) return;

      if (!self.viewer) {
        self.log("3Dmol is not yet ready in displayPDB");
        return
      }

      let urlPrefx = self.getDataApiUrl() + self.dataUrlFromParams(self.$route.params)


      let url = self.selectedPDB.url
        ? urlPrefx + self.selectedPDB.url
        : "https://files.rcsb.org/view/" + pdbid.substring(0, 4) + ".pdb";

      self.log(url);

      window.jQuery.ajax(url, {
        success: function (data) {
          let v = self.viewer;
          v.addModel(data, "pdb"); /* load data */
          v.setStyle({}, { cartoon: { color: "spectrum" } });

          v.zoomTo(); /* set camera */
          v.render(); /* render scene */
          self.parsePdbData(data);
          self.viewStyle.color = "avgmutation";

          if (self.enablePositionSelection) {
            if (self.availableLigands.length) {
              self.positionFilterType = "d";
            } else if (self.availableChains.length) {
              self.positionFilterType = "ch";
            } else {
              self.positionFilterType = "a";
            }
          }
          self.applyPositionsFilter();
          self.recolorStructure();
        },
        error: function (hdr, status, err) {
          self.log("Failed to load PDB " + url + ": " + err);
        },
        async: true,
      });
    },
    parsePdbData(data) {
      const self = this;
      self.availableLigands = [];
      self.availableChains = [];
      self.selectedLigand = null;
      if (!data) return;

      const selectedChain = self.selectedPDB.chain;//"A";

      const valueFrom = function (dataStr, label) {
        const l = label.endsWith(":") ? label : label + ":";
        let parts = dataStr.split(l);
        let v = parts[1].trim();
        v = v.substring(0, v.length - 1);
        return v;
      };

      const mayBeSaveThisMolecule = function (molecule) {
        if (molecule) {
          // vor some PDBs molecule in COMPND section lists sevaral chains. Need to parse them out and add as separate "molecules"
          const chains = molecule.chain.split(",");
          if (chains.length === 1) {
            // simple case, proceed
            molecule.molId != 1 && self.availableChains.push(molecule);
          } else {
            chains.forEach((c) => {
              const z = c.trim();
              if (z != self.selectedPDB.chain) {
                let molZ = {
                  molId: molecule.molId + z,
                  chain: z,
                  id: molecule.id + ":" + z,
                };
                self.availableChains.push(molZ);
              }
            });
          }
          if (self.availableChains.length) { self.selectedAntibody = self.availableChains[0]; }
        }
      };

      let molecule = null;

      let lines = data.split("\n");
      lines.forEach((l) => {
        if (l.startsWith("HET    ")) {
          self.log(l);
          let parts = l.replace(/\s+/g, " ").split(" ");
          if (parts.length > 4) {
            if (parts[2].trim() === selectedChain) {
              self.availableLigands.push({
                id: parts[1].trim(),
                chain: parts[2].trim(),
                resn: parts[1].trim(),
                resi: +parts[3].trim(),
                size: +parts[4],
              });
            }
          }
        } else if (l.startsWith("COMPND")) {
          /* 
COMPND    MOL_ID: 1;                                                            
COMPND   2 MOLECULE: NON-STRUCTURAL PROTEIN 3;                                  
COMPND   3 CHAIN: D;                                                            
COMPND   4 SYNONYM: PAPAIN-LIKE PROTEASE,PP1AB,ORF1AB POLYPROTEIN,NSP3,PL2-PRO, 
COMPND   5 PAPAIN-LIKE PROTEINASE,PL-PRO;                                       
COMPND   6 EC: 3.4.19.121, 3.4.22.-;                                            
COMPND   7 ENGINEERED: YES;                                                     
COMPND   8 MOL_ID: 2;                                                           
COMPND   9 MOLECULE: VIR251;                                                    
COMPND  10 CHAIN: I;                                                            
COMPND  11 ENGINEERED: YES 

---- alt versioon with several chains:

COMPND    MOL_ID: 1;                                                            
COMPND   2 MOLECULE: NON-STRUCTURAL PROTEIN 3;                                  
COMPND   3 CHAIN: A, B, C;                                                      
COMPND   4 SYNONYM: NSP3, PL2-PRO, PAPAIN-LIKE PROTEASE, PAPAIN-LIKE PROTEINASE,
COMPND   5 PL-PRO;                                                              
COMPND   6 EC: 3.4.19.12,3.4.22.-,3.4.22.69;                                    
COMPND   7 ENGINEERED: YES  */
          if (l.indexOf("MOL_ID") > -1) {
            // start of the new mol description
            mayBeSaveThisMolecule(molecule);
            let v = valueFrom(l, "MOL_ID");
            molecule = { molId: +v };
          } else if (l.indexOf("MOLECULE:") > -1) {
            molecule.id = valueFrom(l, "MOLECULE");
          } else if (l.indexOf("CHAIN:") > -1) {
            molecule.chain = valueFrom(l, "CHAIN");
          }
        }
      });
      self.currentLigand = self.availableLigands[0];

      mayBeSaveThisMolecule(molecule);

      // eslint-disable-next-line no-console
      console.log(
        `${self.selectedPDB.id} available chains: ${self.availableChains.length}`
      );
      // eslint-disable-next-line no-console
      // console.log(self.availableChains);

      // eslint-disable-next-line no-console
      console.log(
        `${self.selectedPDB.id} available ligands: ${self.availableLigands.length}`
      );
      // eslint-disable-next-line no-console
      // console.log(self.availableLigands);

      if (self.availableLigands.length > 0) {
        self.selectedLigand = self.availableLigands[0];
      }
    },
    labelAtom(atom) {
      // add atom label in 3d view
      const self = this;
      this.viewer.removeAllLabels();
      if (atom) {
        this.viewer.removeAllLabels();
        const m = this.mAtoms[atom.resi];
        const l = this.labelForAtom(atom, m);
        this.viewer.addLabel(l, {
          fontSize: 12,
          position: atom,
          backgroundColor: "#111111",
          backgroundOpacity: 0.8,
          borderColor: m ? self.getColorForResidue(atom.resi) : "#111111",
          borderThickness: 1.0,
          inFront: true,
        });
      }
    },
    zoomToCodon(pos) {
      this.showRandomSelectBox && this.$parent.zoomToCodon(pos);
    },
    addPosToList(pos) {

      if (!pos) {
        pos = '';
        this.presetPositions.forEach(p => pos += p.pos + ' ');
      }
      this.selectedPositionsStr += ' ' + pos.trim();
    },
    applyPositionsFilter() {
      if (!this.viewer) return;
      const self = this;


      let posList = [];

      self.log(`Setting filtering to ${self.positionFilterType}`);

      if (self.enablePositionSelection) {
        if (self.positionFilterType === "c") {
          let positions = [];
          let cleaned = self.selectedPositionsStr.replaceAll(",", " ");
          let parts = cleaned.replace(/\s+/g, " ").split(" ");
          parts.forEach((p) => positions.push(+p));

          this.filteredMutations =
            this.coordinatesType === "s"
              ? this.mutationsAtStructurePositions(
                this.mutations,
                this.selectedPDB,
                positions
              )
              : this.mutationsAtGenomicPositions(this.mutations, positions);

          this.filteredMutations.forEach((m) => {
            posList.push(self.dna2protS(m.variant));
          });
        } else if (self.positionFilterType === "d") {
          if (!self.currentLigand) self.clearPositionsFilter();
          else {
            const distance = +self.distanceToLigand;
            const atoms = self.viewer.selectedAtoms({
              resn: self.currentLigand.resn,
              chain: self.currentLigand.chain,
              expand: distance,
            });

            atoms.forEach((a) => {
              posList.push(a.resi);
            });
          }
        } else if (self.positionFilterType === "ch") {
          if (!self.selectedAntibody) self.clearPositionsFilter();
          else {
            const atoms = self.viewer.selectedAtoms({
              chain: self.selectedPDB.chain,
              within: {
                sel: { chain: self.selectedAntibody.chain },
                distance: +self.distanceToAntibody,
              },
            });

            atoms &&
              atoms.forEach((a) => {
                posList.push(a.resi);
              });
          }
        } else {
          self.clearPositionsFilter();
        }
      } else {
        self.clearPositionsFilter();
      }
      self.selectedPositions = [...new Set(posList)];

      this.recolorStructure();

      self.$emit("update:v3d-posfilter", self.selectedPositions);
    },
    clearPositionsFilter() {
      this.selectedLigand = null;
      this.positionFilterType = "a";
      this.selectedPositions = [];
      if (this.viewer) {
        this.recolorStructure();
        this.viewer.zoomTo();
      }
    },

    exportPyMol() {
      if (!this.selectedPDB) return;
      const self = this;
      const prefix = "https://viral.pathogens3d.org/";
      const link = prefix + this.selectedPDB.url.replace("./", "");

      var text = "# Coronovirus3d.org generated script.\n";
      text += "# Data may have changed since you downloaded this file. \n";
      text += `load  ${link}`;
      text += "\n";
      text += "color white\n";
      text += "hide sticks,het\n";

      let colors = {};

      Object.keys(this.mAtoms).forEach((x) => {
        const color = self.getColorForResidue(x);
        !colors[color] && (colors[color] = []);
        colors[color].push(x);
      });

      let i = 1;
      Object.keys(colors).forEach((c) => {
        const residues = colors[c].join("+");
        text += `select mutated${i}, resi ${residues}` + "\n";
        text += `color ${c.replace("#", "0x")}, mutated${i}` + "\n";
        i++;
      });

      const data = new Blob([text], { type: "text/plain" });
      this.saveBlob(
        data,
        "bunyavirus3d-" + this.selectedPDB.properties.pdbid + ".pml"
      );
    },
    exportJSON() {
      const data = new Blob([this.viewer.exportJSON()], {
        type: "application/json",
      });
      this.saveBlob(
        data,
        "bunyavirus3d-" + this.selectedPDB.properties.pdbid + ".json"
      );
    },
    exportVRML() {
      const data = new Blob([this.viewer.exportVRML()], {
        type: "model/vrml",
      });
      this.saveBlob(
        data,
        "bunyavirus3d-" + this.selectedPDB.properties.pdbid + ".wrl"
      );
    },
    exportChimera() {
      const pdbid = this.selectedPDB.properties.pdbid;
      const chain = this.selectedPDB.chain;
      const prefix = "http://viral.pathogens3d.org/"; // cannot use https
      const link = prefix + this.selectedPDB.url.replace("./", "");
      const self = this;

      let script = "import chimera as ch\n";
      script += "models = ch.openModels.list()\n";
      script += "found = None\n";
      script += "for m in models:\n";
      script += "    if m.name == '" + pdbid + "':\n";
      script += "        found = m\n";

      script += "if not found:\n";
      script += "    opened = ch.openModels.open('" + link + "')[0]\n";
      script += "else:\n";
      script += "    opened = found\n";

      script += "mod = opened\n";
      script += "mod.display = True\n";
      script += "residues = mod.residues\n";

      script += "for r in residues:\n";
      script += "    for a in r.atoms:\n";
      script += "        a.display = False\n";
      script += "from chimera import runCommand \n";
      script += "runCommand('background solid white')\n";
      script += "runCommand('color silver #0:*')\n";

      script += "runCommand('~display :*')\n";

      Object.keys(this.mAtoms).forEach((residue, i) => {
        const acolor = self.getColorForResidue(residue);

        const color = this.hexColorToRGB(acolor);

        let commandString =
          "colordef color" + i + " " + color.r + " " + color.g + " " + color.b;
        script += "runCommand('" + commandString + "')\n";
        commandString = "color color" + i + " :" + residue + "." + chain;
        script += "runCommand('" + commandString + "')\n";
        commandString = "display :" + residue + "." + chain;
        script += "runCommand('" + commandString + "')\n";
      });

      script += "runCommand('ribbon :*')\n";

      script += "\n";

      const data = new Blob([script], {
        type: "application/octet-stream;charset=utf-8",
      });
      this.saveBlob(data, "bunyavirus3d-" + pdbid + ".py");
    },
    saveBlob(data, filename) {
      try {
        window.saveAs(data, filename);
      } catch (e) {
        // window.open("data:" + m + "," + encodeURIComponent(t), "_blank", "");

        var url = window.URL.createObjectURL(data);
        window.open(url);
      }
    },
    hexColorToRGB(hexColor) {
      const cropped = hexColor.replace("#", "");
      const r = parseFloat("" + parseInt(cropped.substr(0, 2), 16));
      const g = parseFloat("" + parseInt(cropped.substr(2, 2), 16));
      const b = parseFloat("" + parseInt(cropped.substr(4, 2), 16));

      return {
        r: r / 255.0,
        g: g / 255.0,
        b: b / 255.0,
      };
    },
  },
};
</script>

<style scoped>
#structureView {
  /* width: 100%;
  height: 100%; */
  position: relative;
}
</style>